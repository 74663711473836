import * as imagesActionTypes from '../actiontypes/images';


/**
 * Creates action for adding a local image to images managed in redux-store.
 * @function
 * @property {string} id id of image (typically starts with "local-" and is generated by generateLocalImageId() helper func)
 * @property {string} imageData full res image-data (as base64)
 * @property {string} thumbnailData low res image-data used as thumbnail (as base64)
 * @returns {object} action
 */
export function addLocalImage(id, imageData, thumbnailData) {
    return ({
        type: imagesActionTypes.ADD_LOCAL_IMAGE,
        id,
        imageData,
        thumbnailData,
    });
};


/**
 * Creates action for loading a remote images.
 * @function
 * @param {Array<string>} imageIds array of imageIds to load
 * @param {boolean} onlyThumbnails indicates if only the thumbnails should be queried
 * @returns {object} action
 */
export function loadImages(imageIds, onlyThumbnails) {
    return (dispatch) => { 
        const remoteImageIds = imageIds.filter(id => !id.startsWith('local'));
        if (remoteImageIds.length < 1)
            return;

        dispatch({
            type: 'apiCall',
            apiCall: {
                types: [imagesActionTypes.LOAD_IMAGES_REQUEST,
                    imagesActionTypes.LOAD_IMAGES_SUCCESS,
                    imagesActionTypes.LOAD_IMAGES_FAILURE],
                call: (api) => api.request('images/loadImages', { imageIds, onlyThumbnails })
            }
        });
    }
};


/**
 * Creates action for removing images from local store.
 * @function
 * @param {Array<string>} imageIds array of imageIds to remove
 * @returns {object} action
 */
export function removeImages(imageIds) {
    return {
        type: imagesActionTypes.REMOVE_IMAGES,
        imageIds,
    };
};
